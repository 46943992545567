var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "DeliveryCalculation",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "id",
      "select-action": 'update',
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable,
      "stickyColumns": 3,
      "qSkip_FilterButton": true
    },
    on: {
      "selectAction": function ($event) {
        return _vm.onUpdate($event);
      }
    }
  }, 'data-table', _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };