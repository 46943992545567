var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', [_vm._v(" Расчет оптимальной поставки ")]), !_vm.firstTime ? _c('button', {
    staticClass: "action-button btn-outline btn-toggle",
    on: {
      "click": _vm.toggleSettings
    }
  }, [!_vm.showSettings ? _c('span', [_vm._v("Изменить настройки")]) : _vm._e(), _vm.showSettings ? _c('span', [_vm._v("Скрыть настройки")]) : _vm._e(), _c('img', {
    staticClass: "btn-toggle__img",
    attrs: {
      "src": require("../assets/images/icons/settings.png"),
      "alt": "Settings"
    }
  })]) : _vm._e(), _vm.showSettings ? _c('div', {
    staticClass: "settings"
  }, [_c('h2', {
    staticClass: "settings__title"
  }, [_vm._v("Настройки расчета")]), _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_vm._v(" Выберите магазины ")]), _c('div', {
    staticClass: "settings__block__content"
  }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
    staticClass: "highlights__select",
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.marketplace,
      callback: function ($$v) {
        _vm.marketplace = $$v;
      },
      expression: "marketplace"
    }
  }) : _vm._e(), _c('app-select-multi', {
    staticClass: "highlights__select select-marketplaces",
    attrs: {
      "items": _vm.select_tokens_items
    },
    model: {
      value: _vm.selectedTokens,
      callback: function ($$v) {
        _vm.selectedTokens = $$v;
      },
      expression: "selectedTokens"
    }
  })], 1)])]), _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Когда планируется поставка")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Предполагаемая дата отгрузки на склад маркетплейса ")])], 1), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('calendar', {
    attrs: {
      "boundaries": _vm.calendarBoundaries
    },
    model: {
      value: _vm.calendarInternal2,
      callback: function ($$v) {
        _vm.calendarInternal2 = $$v;
      },
      expression: "calendarInternal2"
    }
  })], 1)]), _c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("На сколько дней заполняем склад")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Мы произведем расчет оптимального кол-ва товаров, необходимое на это кол-во дней. Рекомендуем указывать не более 30 дней, чтобы избежать повышения расходов на хранение. ")])], 1), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.days,
      expression: "days"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.days
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.days = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Период анализа для расчёта потребности")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Мы проанализируем данные за этот период и построим прогноз скорости продаж по каждому товару для оптимального заполнения склада на необходимое кол-ве дней. Чем больше период, тем выше точность данных. ")])], 1), _c('div', {
    staticClass: "settings__block__content"
  }, [_vm.calendar ? _c('range-calendar', {
    attrs: {
      "boundaries": _vm.$store.state.connections.calendarBoundaries
    },
    model: {
      value: _vm.calendar,
      callback: function ($$v) {
        _vm.calendar = $$v;
      },
      expression: "calendar"
    }
  }) : _vm._e()], 1)])]), _vm.marketplace.title === 'OZON' ? _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Кластеры для поставки")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Необязательная настройка. Если не выбрать кластеры, мы сделаем общий расчет. А если вы отметите один, несколько или все кластеры - мы просчитаем оптимальное кол-во к поставке на каждый кластер (на основе истории продаж по каждому складу этого кластера) ")])], 1), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('app-select-multi', {
    staticClass: "highlights__select",
    attrs: {
      "items": _vm.select_cluster_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.selectedClusters,
      callback: function ($$v) {
        _vm.selectedClusters = $$v;
      },
      expression: "selectedClusters"
    }
  })], 1)])]) : _vm._e(), _vm.marketplace.title === 'Wildberries' ? _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Склады для поставки")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Необязательная настройка. Если не выбрать склады, мы сделаем общий расчет. А если вы отметите один, несколько или все склады - мы просчитаем оптимальное кол-во к поставке на каждый склад (на основе истории продаж по каждому региону) ")])], 1), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('app-select-multi', {
    staticClass: "highlights__select",
    attrs: {
      "items": _vm.select_stores_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.selectedStores,
      callback: function ($$v) {
        _vm.selectedStores = $$v;
      },
      expression: "selectedStores"
    }
  })], 1)])]) : _vm._e(), _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Коэффициент (опционально)")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Необязательная настройка. Увеличивающий или уменьшающий множитель. Например, используется в случае изменения сезонного спроса или для увеличения поставки на период крупных акций на маркетплейсе. Настроить коэффициент можно и после первичного расчета, для всех или для определенного товара/группы товаров. ")])], 1), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ratio,
      expression: "ratio"
    }],
    staticClass: "input counter",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.ratio
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.ratio = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "settings__row"
  }, [_c('div', {
    staticClass: "settings__block"
  }, [_c('button', {
    staticClass: "action-button btn-blue btn-settings",
    on: {
      "click": _vm.createRequest
    }
  }, [_vm.loadingDeliveryCalc ? _c('span', {
    staticClass: "loading-overlay"
  }, [_c('loading-spinner', {
    staticClass: "spinner"
  }), _c('span', {
    staticClass: "loading-overlay__text"
  }, [_vm._v("Выполняем расчет")]), _c('span', [_vm._v(_vm._s(_vm.progress_show) + " %")])], 1) : _c('span', [_vm._v(" Рассчитать поставку ")])])])]), _vm.calcError ? _c('div', {
    staticClass: "settings__row calcError"
  }, [_c('span', {
    staticClass: "calcError__text"
  }, [_vm._v(_vm._s(_vm.calcError))])]) : _vm._e()]) : _vm._e(), !_vm.showSettings ? _c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "mt32"
  }, [_c('div', {
    staticClass: "table__title__wrap"
  }, [_c('h2', {
    staticClass: "settings__title"
  }, [_vm._v(" Детализация поставки ")]), _c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1)]), _vm.reportSettings ? _c('delivery-calculation-table', {
    key: _vm.tableKey,
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "selectable": true,
      "paginatable": true,
      "configurable": true,
      "file-name": _vm.fileName
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };